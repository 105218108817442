@use 'variable' as var;

.header {
  width: 100%;
  height: 72px;
  display: flex;
  justify-content: center;
  align-content: center;
  background-color: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);
  position: fixed;
  top: 0;
  z-index: 32768;

  img.header-logo {
    height: 72px;
    // display: none;
  }

  img#after {
    // display: none;
  }
}

p.section {
  font-size: 30px;
  margin-bottom: 24px;
}

.sns {
  padding-bottom: 64px;
  .boxes {
    .box {
      display: flex;
      margin: 48px 0;
      color: var.$text-color;

      img {
        width: 64px;
      }
      .overwiew {
        margin-left: 32px;
        p.title {
          font-size: 20px;
          word-wrap: break-word;
          margin-bottom: 12px;
        }
      }
    }
  }
}

footer.footer {
  width: 100%;
  padding: 24px 0;

  p {
    font-size: 13.5px;
    text-align: center;
  }
}
