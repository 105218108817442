@use 'variable' as var;

* {
  margin: 0;
  padding: 0;
  font-family: BlinkMacSystemFont, -apple-system, Hiragino Kaku Gothic ProN, 'Roboto', 'Noto Sans JP', sans-serif;
  line-height: 1.5;
}

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialicons/v126/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format('woff2');
}

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP&family=Roboto&display=swap');

.m-i {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  letter-spacing: normal;
  text-transform: none;
  display: inline-flex;
  vertical-align: middle;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
}


html, body, .app {
  width: 100%;
  color: var.$text-color;
  background-color: var.$bg-color;
}

#root {
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 767px) {
    display: block;
  }
}


a {
  text-decoration: none;
}

section {
  padding: 156px 84px;
  max-width: 1024px;

  @media screen and (max-width: 767px) {
    & {
      padding: 128px 24px;
    }
  }
}
