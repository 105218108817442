@use 'variable' as var;

.profire {
  p.name-mittsu {
    font-size: 30px;
    font-weight: bold;
    text-align: center;
  }

  p.name-mittsu-en {
    font-size: 20px;
    text-align: center;
    margin-bottom: 148px;
    color: var.$text-light;
  }
  .detail {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .box {
      width: calc(100% / 3);
      text-align: center;
      margin: 14px 0;

      .m-i {
        font-size: 58px;
      }

      .box-text {
        font-size: 18px;
      }

      @media screen and (max-width: 767px) {
        & {
          width: 50%;
        }
      }
    }
  }
}
