@use 'variable' as var;

.photos {
  .photos-section {
    padding: 0 84px;
    max-width: 1024px;

    @media screen and (max-width: 767px) {
      & {
        padding: initial;
        margin-left: 24px;
      }
    }
  }

  .photo-boxes {
    display: flex;
    flex-wrap: wrap;

    img.box {
      width: calc(100% / 3 - 2px);
      border: 1px solid var.$bg-color;
      
      @media screen and (max-width: 767px) {
        width: calc(50% - 2px);
      }
    }
  }

  .btn-box {
    text-align: center;
    margin-top: 64px;

    a.more-button {
      font-size: 18px;
      color: var.$text-color;
      border: 1px solid var.$text-color;
      padding: 12px 64px;
    }
  }
}



