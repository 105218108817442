.hero {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;

  .overlay {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  img {
    // display: none;
    width: 90%;
    max-width: 1024px;
    @media screen and (max-width: 767px) {
      width: initial;
      max-width: initial;
      transform: rotate(90deg);
      max-width: calc(95vh - 72px);
    }
  }
}